<template>
  <div class="Deposit">
    <div class="">
      <div class="user-panel-title-box">
        <h2>Deposit</h2>
        <!--        <br/>-->
        <!--        <h4 style="margin-top: 4px">We offer the following payment methods</h4>-->
      </div><!-- end user-panel-title-box -->

      <div v-if="showBound">
        <h4 style="color: Red;">{{ withdrawBound.title }}</h4>
        <div class="form">
          <div class="item1">
            <!--            <div class="item-label">Upgrade to VIP</div>-->
            <div class="item1">Required for VIP upgrade</div>
            <div class="item-control">
              <!--              <input type="number" placeholder="Please enter amount " v-model="amount">-->
              <span style="color:red;">$ {{ withdrawBound.toVipAmount }}</span>
            </div>
          </div>
          <div class="item1" style="margin-top: 1.25rem">
            <div class="item1">Total deposit</div>
            <div class="item-control">
              <span style="color:green;">$ {{ withdrawBound.depositAmount }}</span>
            </div>
          </div>
          <div class="item1" style="margin-top: 1.25rem">
            <div class="item1">Required for deposit</div>
            <div class="item-control">
              <span style="color:red;">$ {{ withdrawBound.remainingAmount }}</span>
            </div>
          </div>
          <div class="item1" style="margin-top: 1.25rem"
               v-if="withdrawBound.remark!=='' && withdrawBound.remark!==null">
            <div class="item1">Notes</div>
            <div class="item-control">
              <span style="color:black;">{{ withdrawBound.remark }}</span>
            </div>
          </div>
        </div>
      </div>


      <div class="profile-setting-panel-wrap">

        <!--        <ul class="nav nav-tabs nav-tabs-s1 nav-tabs-mobile-size" id="myTab" role="tablist">-->
        <!--          <li class="nav-item" role="presentation" v-for="tab in tablist" :key="tab.id">-->
        <!--            <button class="nav-link" :class="tab.isActive" :id="tab.slug" data-bs-toggle="tab"-->
        <!--                    :data-bs-target="tab.bsTarget" type="button">-->
        <!--              {{ tab.title }}-->
        <!--              <img v-if="tab.icon" :src="tab.icon" alt="icon" style="width: 36px; margin-right: 5px;"/>-->
        <!--            </button>-->
        <!--          </li>-->
        <!--        </ul>-->

        <div class="tab-content mt-4" id="myTabContent">
          <div class="tab-pane fade show active" id="cryptocurrency" role="tabpanel"
               aria-labelledby="cryptocurrency-tab">
            <div class="profile-setting-panel">
              <div class="form" v-loading="load">
                <div class="item">
                  <div class="item-label">Amount (USD)</div>
                  <div class="item-control">
                    <input type="number" placeholder="Please enter amount " v-model="amount">
                  </div>
                </div>

                <div class="item" style="margin-top: 1.25rem; position: relative;">
                  <div class="item-label">Gateway</div>


                  <!--                  <div class="dropdown">-->
                  <!--                    <div class="dropdown-menu card-generic p-2 keep-open w-60 mt-1 show"-->
                  <!--                         data-popper-placement="bottom-start"-->
                  <!--                         style=" inset: 0px auto auto 0px; margin: 0px;">-->
                  <!--                      <div @click="selectCoinAddress(item)" class="dropdown-item card-generic-item" v-for="item in coinAddress" :key="item.id">-->
                  <!--                        <div :class="{'card-generic-item-active': currentCoin?.id === item.id}">-->
                  <!--                          <img style="width:28px;" :src="require('@/images/icon/'+ item.alias.toLowerCase() + '.svg')"  alt="svg"/>-->
                  <!--                          <span style="margin-left: 5px;">{{ item.name }} </span>-->
                  <!--                        </div>-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <el-select v-model="currentCoin" :placeholder="currentCoin ? null : 'Select gateway'"
                             class="large-select" @change="handleChange" value-key="id">
                    <template v-if="currentCoin" #prefix>
                      <img :src="getIconUrl(currentCoin.alias)" alt="icon" class="icon"/>
                      <span :style="{ color: '#000' }">{{ currentCoin.name }}</span>
                    </template>
                    <!--                    :label="item.name"-->
                    <el-option
                        v-for="item in coinAddress"
                        :key="item.id"
                        :value="item"
                    >
                      <div class="option-content">
                        <img :src="getIconUrl(item.alias)" alt="icon" class="icon"/>
                        <span>{{ item.name }}</span>
                      </div>
                    </el-option>
                  </el-select>


                </div>
                <div class="mt-3" v-if="alert.show">
                  <el-alert show-icon :title="alert.text" type="error" :closable="false"></el-alert>
                </div>

                <div class="row gy-3 but-wrap mt-3">
                  <div class="col-12 col-sm-6 convert"
                       v-if="currentCoin && (amount !== null && amount !== undefined && amount !== '')">
                    <div class="me-3 notranslate">
                      <!--                      <p class="p1"> Convert </p>-->
                      <p class="p2"> {{ amount }} USD = {{ convert }}
                        <img :src="require('@/images/icon/'+ currentCoin.alias.toLowerCase() + '.svg')" alt="svg"/>
                        {{ currentCoin.alias }}
                      </p>
                    </div>

                  </div>
                  <div class="col-12 col-sm-6 justify-content-lg">
                    <button class="btn btn-grad w-100" style="max-width: 300px" :disabled="!(currentCoin && amount)"
                            @click="nextAction"> Next
                    </button>
                  </div>
                </div>
              </div>
            </div><!-- end profile-setting-panel -->
          </div><!-- end tab-pane -->
          <div class="tab-pane fade" id="debit-or-credit-card" role="tabpanel"
               aria-labelledby="debit-or-credit-card-tab">
            <!--            <img src="@/images/tmp/Visacard.png" alt="visa" style="width: 50px;"/>-->
            <!--            <img src="@/images/tmp/Mastercard.png" alt="master" style="width: 50px;"/>-->

            <div class="form" v-loading="load">
              <div class="item">
                <div class="item-label">Amount (USD)</div>
                <div class="item-control">
                  <input type="number" placeholder="Please enter amount " v-model="amount">
                </div>
                <div class="mt-3" v-if="alert.show">
                  <el-alert show-icon :title="alert.text" type="error" :closable="false"></el-alert>
                </div>
                <div class="col-12 col-sm-6 justify-content-lg mt-3">
                  <button class="btn btn-grad w-100" style="max-width: 300px" @click="depositUSD"> Submit</button>
                </div>
              </div>
            </div>

          </div><!-- end tab-pane -->

        </div><!-- end tab-content -->


      </div><!-- end profile-setting-panel-wrap-->
      <my-deposit-history-section class="mt-4" type-text="History" type="50"></my-deposit-history-section>
    </div><!-- end col-lg-8 -->

    <!-- Modal -->
    <!--    <b-modal></b-modal>-->


    <div class="modal fade" style="background: rgba(0,0,0,0.4);" :class="{show: exampleModal}" id="exampleModal"
         tabindex="-1" aria-labelledby="exampleModalLabel" :style="{display: exampleModal ? 'block' : 'none'}">
      <div class="modal-dialog w-100 modal-dialog-centered" style="max-width: 990px;margin:1rem auto">
        <div class="modal-content  ">
          <div class="modal-header">
            <!--            <h5 class="fs-5">Modal title</h5>-->
            <span style="font-size: 16px;font-weight: 600;">Payment</span>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    @click="closeExampleModal">
              <img :src="btnClose" alt="">
            </button>
          </div>
          <div class="info-wrap">
            After successful payment, please save the screenshot and upload the receipt to receive credit.
          </div>
          <div class="modal-body p-3">
            <div class="pay-info row">
              <div class="pay-info_right col-12 col-md-8 mt-md-gs">
                <div class="item">
                  <div class="label">Address</div>
                  <div class="value text-center text-sm-left notranslate">
                    <p class="w-sm-auto  text-truncate" style="word-break: break-all; font-size: 20px;"
                       v-clipboard:copy="walletAddress"
                       v-clipboard:success="copysuccess" v-clipboard:error="copyerror"> {{ walletAddress }} </p>
                    <el-icon class="ms-sm-3" v-clipboard:copy="walletAddress" v-clipboard:success="copysuccess"
                             v-clipboard:error="copyerror">
                      <CopyDocument/>
                    </el-icon>
                  </div>
                </div>
                <div class="convert mt-4">
                  <div class="me-2">
                    <p class="p1"> Convert </p>
                    <p class="p2 notranslate"> {{ amount }} $ =
                      <span v-clipboard:copy="convert"
                            v-clipboard:success="copysuccess" v-clipboard:error="copyerror">{{ convert }}
                      </span>
                      <img v-if="currentCoin.alias"
                           :src="require('@/images/icon/'+ currentCoin.alias.toLowerCase() + '.svg')" alt="svg"/>
                      {{ currentCoin.alias }}
                    </p>
                    <p>
                      <el-icon class="ms-sm-3" v-clipboard:copy="convert" v-clipboard:success="copysuccess"
                               v-clipboard:error="copyerror">
                        <CopyDocument/>
                      </el-icon>
                    </p>
                  </div>
                </div>
                <div class="mt-3" v-if="alert.show">
                  <el-alert show-icon :title="alert.text" type="error" :closable="false"></el-alert>
                </div>

                <div class="el-upload-list-wrap mt-3">
                  <ul class="el-upload-list el-upload-list--text">
                    <li class="el-upload-list__item is-success" :tabindex="index" v-for="(file, index) in fileList"
                        :key="file.lastModified">
                      <!--v-if-->
                      <div class="el-upload-list__item-info">
                        <a class="el-upload-list__item-name">
                          <el-icon>
                            <Document/>
                          </el-icon>
                          <span class="el-upload-list__item-file-name"
                                title="element-plus-logo.svg">{{ file.name }}</span>
                        </a><!--v-if-->
                      </div>
                      <label class="el-upload-list__item-status-label d-inline-flex align-items-center">
                        <el-icon class="el-icon--upload-success">
                          <CircleCheckFilled/>
                        </el-icon>
                      </label>
                      <el-icon class="el-icon--close" @click="handleRemove">
                        <Close/>
                      </el-icon>
                      <!--v-if-->
                    </li>
                  </ul>
                </div>

                <div class="btns-wrap d-flex flex-column flex-sm-row">
                  <el-upload
                      v-model:file-list="fileList"
                      ref="upload"
                      class="upload-demo"
                      :auto-upload="false"
                      :limit="1"
                      :on-exceed="handleExceed"
                      :show-file-list="false"
                      v-if="fileList.length === 0"
                  >
                    <template #trigger>
                      <button class="w-100 btn btn-grad mt-4 ">Upload Receipt</button>
                    </template>
                  </el-upload>
                  <!--             btn btn-dark  me-sm-md mt-3  -->
                  <button class="btn btn-grad mt-3" v-if="fileList.length !== 0" @click="preDepositModification">
                    Submit
                  </button>
                </div>
              </div>
              <div class="pay-info_codeQr col-12 col-md-4 text-center">
                <el-image fit="fill" :src="QrCode"></el-image>
              </div>
            </div>
          </div>
          <!--          <el-alert class="text-center" type="warning" show-icon :closable="false"-->
          <!--                    title="Please transfer the exact amount to address">-->
          <!--          </el-alert>-->
          <!--          <div class="modal-footer">-->
          <!--            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeExampleModal">Close</button>-->
          <!--            <button type="button" class="btn btn-primary">Save changes</button>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import coinAddressApi from "@/apis/coinAddressApi"
import indexApi from "@/apis/indexApi";
import btnClose from "@/images/icon/btn-close.svg"
import walletApi from "@/apis/walletApi";
import MyDepositHistorySection from "@/components/n1/MyDepositHistorySection";

export default {
  name: "Deposit",
  components: {MyDepositHistorySection},

  data() {
    return {
      showBound: false,
      withdrawBound: {
        isShow: 0,
        title: '',
        toVipAmount: 0,
        depositAmount: 0,
        remainingAmount: 0,
        remark: ''
      },
      btnClose: btnClose,
      coinAddress: [],
      currentCoin: '',
      // convert: '1000usdt=1000000.EHT',
      amount: '',
      roe: {
        price: 1
      },
      load: false,
      type: "usdt",
      min: 0,
      rate: '',
      exampleModal: false,
      QrCode: '',
      walletAddress: '',
      submitForm: {
        transactionId: '',
        addressConfId: "",
        amount: "",
        rate: '',
        voucher: undefined,
        currency: 'USD',

      },
      btnData: [
        {
          btnClass: 'btn-lg btn-dark',
          title: 'Explore',
          path: '/explore'
        },
        {
          btnClass: 'btn-lg btn-outline-dark',
          title: 'Create',
          path: '/create'
        }
      ],
      alert: {
        text: "",
        show: false
      },
      fileList: [],
      tablist: [
        {
          id: 1,
          isActive: 'active',
          title: 'Cryptocurrency',
          slug: 'cryptocurrency-tab',
          bsTarget: '#cryptocurrency'
        },
        // {
        //   id: 2,
        //   title: 'Debit Or Credit Card',
        //   slug: 'debit-or-credit-card-tab',
        //   bsTarget: '#debit-or-credit-card',
        //   icon:  require('@/images/tmp/Visacard.png')
        // },
      ],
      creatorList: [
        {
          id: 1,
          title: '@nathan_walls',
          subTitle: '24.13 ETH',
          img: require('@/images/thumb/avatar.jpg'),
          path: '/author'
        },
        {
          id: 2,
          title: '@alex_smith',
          subTitle: '12.24 ETH',
          img: require('@/images/thumb/avatar-2.jpg'),
          path: '/author'
        },
        {
          id: 3,
          title: '@kevin_martin',
          subTitle: '13.57 ETH',
          img: require('@/images/thumb/avatar-3.jpg'),
          path: '/author'
        },
        {
          id: 4,
          title: '@david_martin',
          subTitle: '34.13 ETH',
          img: require('@/images/thumb/avatar-4.jpg'),
          path: '/author'
        },
        {
          id: 5,
          title: '@jhonatan_doe',
          subTitle: '23.24 ETH',
          img: require('@/images/thumb/avatar-5.jpg'),
          path: '/author'
        },
        {
          id: 6,
          title: '@mark_hardson',
          subTitle: '34.57 ETH',
          img: require('@/images/thumb/avatar-6.jpg'),
          path: '/author'
        },
      ],
    }
  },
  computed: {
    convert: function () {
      if (!this.amount || !this.currentCoin) return '0.00';
      return ((this.amount / this.roe.price)).toFixed(8);
    }
  },
  created() {
    walletApi.preWithdrawCheck(res => {
      if (res.data !== 'pass') {
        let extraJson = res.data.extraJson
        if (extraJson !== null && extraJson !== '') {
          let innerBound = {
            isShow: extraJson.isShow || 0,
            title: extraJson.title || '',
            toVipAmount: extraJson.toVipAmount || 0,
            depositAmount: extraJson.depositAmount || 0,
            remainingAmount: extraJson.remainingAmount || 0,
            remark: extraJson.remark || ''
          }
          try {
            const parsedJson = JSON.parse(extraJson)
            innerBound = {...innerBound, ...parsedJson}
            if (innerBound.isShow === 1) {
              this.showBound = true
            } else {
              this.showBound = false
            }
          } catch (error) {
            console.error('Invalid JSON string:', error)
          }
          this.withdrawBound = innerBound
        }
      }
    })
    coinAddressApi.list(res => {
      this.coinAddress = res.data
    })
  },
  methods: {
    handleRemove() {
      this.fileList = []
    },
    handleExceed(files) {
      console.log(files)
      this.fileList = files;
    },
    getIconUrl(alias) {
      return require(`@/images/icon/${alias.toLowerCase()}.svg`);
    },
    nextAction() {
      if (this.amount < 200) {
        console.log(this.amount + '<200')
        this.alert.show = true
        this.alert.text = "Minimum deposit amount is $200."
        return
      }
      if (this.currentCoin === "") {
        this.alert.show = true
        this.alert.text = "Please fill out the form"
        return
      }
      this.submitForm.addressConfId = this.currentCoin.id
      if (this.amount === "" || this.amount <= 0) {
        this.alert.show = true
        this.alert.text = "Amount cannot be empty"
        return
      }
      // this.load = true
      this.submitForm.amount = this.amount === undefined ? '0.00' : ((this.amount / this.roe.price)).toFixed(8)
      this.submitForm.rate = this.rate
      walletApi.preDeposit(this.submitForm, res => {
        // this.load = false
        if (res.code !== 200) {
          this.alert.show = true
          this.alert.text = res.msg
        } else {
          this.submitForm.transactionId = res.data
          // this.$message.success("Success, your order is pending..")
          this.alert.show = false
        }
      })
      this.exampleModal = true;
    },
    preDepositModification() {
      if (this.submitForm.transactionId === "") {
        this.alert.show = true
        this.alert.text = "Parameter error."
        return
      }
      if (this.amount === "") {
        this.alert.show = true
        this.alert.text = "Amount cannot be empty"
        return
      }
      if (this.fileList.length === 0) {
        this.alert.show = true
        this.alert.text = "Voucher cannot be empty"
        return
      }
      // this.load = true
      this.submitForm.amount = this.amount === undefined ? '0.00' : ((this.amount / this.roe.price)).toFixed(8)
      this.submitForm.rate = this.rate
      this.submitForm.voucher = this.fileList[0].raw;
      walletApi.preDepositModification(this.submitForm, res => {
        // this.load = false
        if (res.code !== 200) {
          this.alert.show = true
          this.alert.text = res.msg
        } else {
          this.$message.success("Pending..")
          this.alert.show = false
          this.submitForm = {
            transactionId: '',
            addressConfId: "",
            amount: "",
            rate: '',
            voucher: ""
          }
          this.roe = {
            price: 1
          }
          this.walletAddress = ""
          this.QrCode = ""
          this.min = 0
          this.amount = 0
          this.rate = ''
          this.exampleModal = false
        }
      })
    },


    deposit() {
      // if (!this.currentCoin) {
      //   this.alert.show = true
      //   this.alert.text = "Please fill out the form"
      //   return
      // }
      if (this.fileList.length === 0) {
        this.alert.show = true
        this.alert.text = "Voucher cannot be empty"
        return
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // background: 'rgba(var(--bs-link-color-rgb))',
      })
      // this.load = true
      this.submitForm.voucher = this.fileList[0].raw;
      this.submitForm.amount = this.amount == undefined ? '0.00' : ((this.amount / this.roe.price)).toFixed(8)
      this.submitForm.rate = this.rate
      walletApi.deposit(this.submitForm, res => {
        // this.load = false
        loading.close()
        if (res.code !== 200) {
          this.alert.show = true
          this.alert.text = res.msg
        } else {
          this.$message.success("Pending..")
          this.alert.show = false
          this.submitForm = {
            addressConfId: "",
            amount: "",
            rate: '',
            voucher: ""
          }
          this.roe = {
            price: 1
          }
          this.walletAddress = ""
          this.QrCode = ""
          this.min = 0
          this.amount = 0
          this.rate = ''
          this.closeExampleModal()
        }
      })
    },
    depositUSD() {
      if (this.amount === "") {
        this.alert.show = true
        this.alert.text = "Amount cannot be empty"
        return
      }
      if (this.amount < 50) {
        this.alert.show = true
        this.alert.text = "The minimum amount is 50"
        return
      }
      this.submitForm.amount = this.amount
      walletApi.creditDeposit(this.submitForm, res => {
        this.load = false
        if (res.code !== 200) {
          this.alert.show = true
          this.alert.text = res.msg
        } else {
          //this.$message.success("Success, your order is pending..")
          console.log("get paymentLink:" + res.data)
          this.alert.show = false
          this.amount = ''
          window.location.href = res.data
        }
      })
    },
    closeExampleModal() {
      this.exampleModal = false;
      this.fileList = [];
    },
    copysuccess() {
      this.$message.success("Copied")
    },
    copyerror() {
      this.$message.success("Fail")
    },
    handleChange(item) {
      this.selectCoinAddress(item)
    },
    selectCoinAddress(item) {
      if (!this.amount) {
        this.$messageBox.alert(
            'Please enter the amount first.',
            'Error',
            {
              type: 'error',
              center: false,
            })
        this.currentCoin = ''
        return
      }
      this.currentCoin = item;
      this.submitForm.addressConfId = item?.id || '';
      this.load = true
      let data = item;
      this.walletAddress = data.address
      this.QrCode = data.addressQrCode
      this.type = data.alias
      this.min = data.minimumWithdrawAmount
      indexApi.roe(data.alias, res => {
        this.load = false
        this.roe = res.data == null ? 1 : res.data
        this.rate = res.data.price
      })

    }
  }
}
</script>

<style scoped lang="scss">


.card-generic-item-active {
  color: red;
}

.dropdown-menu {
  z-index: 2;
  position: relative;
}


.form {
  //width: 687px;
  //height: 370px;
  background: #F8F9FC;
  //background: lightgray;
  border-radius: 0.5rem;
  padding: 1.3125rem;

  .item {
    &-label {
      height: 1.3125rem;
      font-size: .9375rem;
      //font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333947;
      line-height: 1.3125rem;
    }

    &-control {
      margin-top: 0.625rem;

      input {
        width: 100%;
        height: 2.6875rem;
        border: 1px solid #FFFFFF;
        padding: 0 1rem;
        background: #FFFFFF;
        border-radius: 4px;
        //height: 1.3125rem;
        font-size: .9375rem;
        //font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1C2A46;
        line-height: 2.6875rem;
        //caret-color:  rgba(var(--bs-link-color-rgb));
        transition: all .3s ease;

        &:focus {
          outline: none;
          border: 1px solid rgba(var(--bs-link-color-rgb));
        }

        &::-webkit-input-placeholder {
          /* WebKit browsers，webkit内核浏览器 */
          color: #8091A7;
        }

        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #8091A7;

        }

        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #8091A7;

        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #8091A7;

        }


      }


      .select {
        //margin: 0;
        &-i {
          background: #FFFFFF;
          border-radius: 4px;
          padding: 1.25rem 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border: 1px solid transparent;
          transition: all 0.3s ease;

          &.active {
            border-color: rgba(var(--bs-link-color-rgb));
          }

          span {
            margin-top: 0.625rem;
            font-size: 0.9375rem;
            //font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1C2A46;
            line-height: 1.3125rem;
          }
        }
      }
    }
  }

}

.info-wrap {
  background: #F4F4F4;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  color: red;
  line-height: 1.375rem;
  padding: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}


.convert {
  display: flex;
  align-items: flex-end;
  font-size: 1rem;
  line-height: 1.375rem;

  .p1 {
    color: #1C2A46;
  }

  .p2 {
    color: red;
    margin-top: 2px;
    //font-weight: bold;
  }
}

.pay-info {
  &_codeQr {

  }

  &_right {
    .item {
      .label {
        font-size: 0.9375rem;
        //font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1C2A46;
        line-height: 1.3125rem;
      }

      .value {
        font-size: 1.125rem;
        //font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1C2A46;
        line-height: 1.5625rem;
        margin-top: 0.1875rem;
      }
    }

    .convert {
      //margin-top: 1.5625rem;
    }
  }

}

.el-icon {
  cursor: pointer;

  &:hover {
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  }
}

.el-upload-list-wrap {
  .el-upload-list__item {
    .el-upload-list__item-status-label {
      display: block;
    }

    .el-icon--close {
      display: none;
    }

    &:hover {
      .el-upload-list__item-status-label {
        display: none;
      }

      .el-icon--close {
        display: block;
      }
    }
  }

}

.upload-demo {
  ::v-deep .el-upload {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 70vw;
  }
}

.large-select .el-input__inner {
  display: flex;
  align-items: center;
}

.large-select .icon {
  width: 24px;
  margin-right: 5px;
}

.option-content {
  display: flex;
  align-items: center;
}

.icon {
  width: 28px;
  height: 28px;
  margin-right: 5px;
}


.el-select {
  margin-top: 5px;
  width: 100%;
  border-radius: 4px;
  font-size: 0.9375rem;
  font-weight: 400;
  color: #1C2A46;
  line-height: 2.6875rem;
  transition: all 0.3s ease;
}


</style>
